export const GET_PRODUCTION_REQUEST = 'GET_PRODUCTION_REQUEST';
export const GET_PRODUCTION_RECEIVE = 'GET_PRODUCTION_RECEIVE';

export const CREATE_PRODUCTION_REQUEST = 'CREATE_PRODUCTION_REQUEST';
export const CREATE_PRODUCTION_RECEIVE = 'CREATE_PRODUCTION_RECEIVE';

export const EDIT_PRODUCTION_REQUEST = 'EDIT_PRODUCTION_REQUEST';
export const EDIT_PRODUCTION_RECEIVE = 'EDIT_PRODUCTION_RECEIVE';

export const DELETE_PRODUCTION_REQUEST = 'DELETE_PRODUCTION_REQUEST';
export const DELETE_PRODUCTION_RECEIVE = 'DELETE_PRODUCTION_RECEIVE';

export const GET_TOTAL_PRODUCTION_REQUEST = 'GET_TOTAL_PRODUCTION_REQUEST';
export const GET_TOTAL_PRODUCTION_RECEIVE = 'GET_TOTAL_PRODUCTION_RECEIVE';
