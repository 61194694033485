import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsInEng from '../locales/en/translation.json';
import translationsInFr from '../locales/fr/translation.json';
import translationsInItalian from '../locales/it/translation.json';

const resources = {
    en: {
        translation: translationsInEng
    },
    de: {
        translation: translationsInFr
    },
    it: {
        translation: translationsInItalian
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'fr',
    debug: true,
    fallbackLng: 'de',
    interpolation: {
        escapeValue: false
    },
    ns: 'translation',
    defaultNS: 'translation'
});

export default i18n;
