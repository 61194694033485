import * as TYPES from './types';

// Get Productions
export const getProductionsRequest = (payload) => ({
    type: TYPES.GET_PRODUCTION_REQUEST,
    payload
});
export const getProductionsReceive = (payload) => ({
    type: TYPES.GET_PRODUCTION_RECEIVE,
    payload
});

//Create Production
export const createProductionRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PRODUCTION_REQUEST,
    payload,
    closeModal
});
export const createProductionReceive = (payload) => ({
    type: TYPES.CREATE_PRODUCTION_RECEIVE,
    payload
});

export const editProductionRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_PRODUCTION_REQUEST,
    payload,
    closeModal
});
export const editProductionReceive = (payload) => ({
    type: TYPES.EDIT_PRODUCTION_RECEIVE,
    payload
});

//Delete Productions
export const deleteProductionRequest = (id, name) => ({
    type: TYPES.DELETE_PRODUCTION_REQUEST,
    id,
    name
});
export const deleteProductionReceive = (id) => ({
    type: TYPES.DELETE_PRODUCTION_RECEIVE,
    id
});

// Get Total Productions
export const getTotalProductionsRequest = (payload) => ({
    type: TYPES.GET_TOTAL_PRODUCTION_REQUEST,
    payload
});
export const getTotalProductionsReceive = (payload) => ({
    type: TYPES.GET_TOTAL_PRODUCTION_RECEIVE,
    payload
});
