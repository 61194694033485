import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Productions
export function* getProductionsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getProductionsRequest, payload);

        yield put(ACTIONS.getProductionsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
// Create Production
export function* createProductionSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createProductionRequest, payload);
        yield put(ACTIONS.createProductionReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Get Total Productions

export function* getTotalProductionsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTotalProductionsRequest, payload);

        yield put(ACTIONS.getTotalProductionsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* editProductionSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));

        const { data } = yield call(APIS.editProductionRequest, payload);

        yield put(ACTIONS.editProductionReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('Update Production success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}

//Delete Production
export function* deleteProductionSaga({ id, name }) {
    try {
        yield put(setLoadingStatus(true));

        yield call(APIS.deleteProductionRequest, id, name);
        yield put(ACTIONS.deleteProductionReceive(id, name));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* ProductionsSagas() {
    yield takeLatest(TYPES.GET_PRODUCTION_REQUEST, getProductionsSaga);
    yield takeLatest(TYPES.CREATE_PRODUCTION_REQUEST, createProductionSaga);
    yield takeLatest(TYPES.DELETE_PRODUCTION_REQUEST, deleteProductionSaga);
    yield takeLatest(TYPES.EDIT_PRODUCTION_REQUEST, editProductionSaga);
    yield takeLatest(TYPES.GET_TOTAL_PRODUCTION_REQUEST, getTotalProductionsSaga);
}
